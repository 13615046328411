.adminHeaderClass {
    background-color: #fff;
    width: 100vw;
    box-shadow: 0 5px 10px 0 rgba(0, 0, 0, .2);
    height: 67px;
    z-index: 50;
}

.MobileScreen {
    padding-top: 67px;

}

.BigScreen {
    padding-top: 67px;
    padding-left: 260px;
    transition: all 300ms;
}


.SmallScreen {
    padding-top: 67px;
    padding-left: 100px;
    transition: all 300ms;
}


.containerss {
    background: white;
    cursor: pointer;
    border-radius: 15px;
    position: relative;
    height: 195px;
    padding: 25px 30px;
    color: #000;
}

.border-radius-banner {
    border-radius: 15px;
}

.containerss::after {
    content: '';
    background: #97c0e7;
    border-radius: 15px;
    height: 100%;
    width: 100%;
    position: absolute;
    left: 0;
    top: 0;
    z-index: 0;
    clip-path: circle(10% at 0% 0%);
    transition: all .3s ease-in;
}

.contentss {
    position: relative;
    z-index: 1;
    transition: all .3s ease-in;
}

.containerss:hover::after {
    clip-path: circle(100%);
}

.banner-hoverd::after {
    clip-path: circle(100%);
}

.containerss:hover .contentss {
    color: #000;
}

.switch {
    position: relative;
    display: inline-block;
    width: 60px;
    height: 34px;
}

/* Hide default HTML checkbox */
.switch input {
    opacity: 0;
    width: 0;
    height: 0;
}

.slider {
    position: absolute;
    cursor: pointer;
    top: 0;
    left: 0;
    right: 0;
    bottom: 0;
    background-color: #ccc;
    -webkit-transition: .4s;
    transition: .4s;
}

.slider:before {
    position: absolute;
    content: "";
    height: 26px;
    width: 26px;
    left: 4px;
    bottom: 4px;
    background-color: white;
    -webkit-transition: .4s;
    transition: .4s;
}

input:checked+.slider {
    background-color: #2196F3;
}

input:focus+.slider {
    box-shadow: 0 0 1px #2196F3;
}

.slider.round {
    border-radius: 34px;
}

.slider.round:before {
    border-radius: 50%;
}

input:checked+.slider:before {
    -webkit-transform: translateX(26px);
    -ms-transform: translateX(26px);
    transform: translateX(26px);
}

@media screen and (max-width: 992px) {

    .BigScreen,
    .SmallScreen {
        padding-top: 67px;
        padding-left: 0;
        transition: all 300ms;
    }
}

.mine-card {
    padding: 1rem;
    display: flex;
    flex-direction: column;
    align-items: center;
}


.mine-img {
    width: 100%;
    min-height: 150px;
    display: flex;
    align-items: center;
    justify-content: center;
}

.mine-img>img {
    height: 100%;
    object-fit: cover;
}

.miner-detail {
    display: flex;
    flex-wrap: wrap;
    justify-content: space-between;

}

.login-root {
    background: #fff;
    display: flex;
    width: 100%;
    min-height: 100vh;
    overflow: hidden;
}

.loginbackground {
    min-height: 692px;
    position: fixed;
    bottom: 0;
    left: 0;
    right: 0;
    top: 0;
    z-index: 0;
    overflow: hidden;
}

.flex-flex {
    display: flex;
}

.align-center {
    align-items: center;
}

.center-center {
    align-items: center;
    justify-content: center;
}

.box-root {
    box-sizing: border-box;
}

.flex-direction--column {
    -ms-flex-direction: column;
    flex-direction: column;
}

.loginbackground-gridContainer {
    display: -ms-grid;
    display: grid;
    -ms-grid-columns: [start] 1fr [left-gutter] (86.6px) [16] [left-gutter] 1fr [end];
    grid-template-columns: [start] 1fr [left-gutter] repeat(16, 86.6px) [left-gutter] 1fr [end];
    -ms-grid-rows: [top] 1fr [top-gutter] (64px) [8] [bottom-gutter] 1fr [bottom];
    grid-template-rows: [top] 1fr [top-gutter] repeat(8, 64px) [bottom-gutter] 1fr [bottom];
    justify-content: center;
    margin: 0 -2%;
    transform: rotate(-12deg) skew(-12deg);
}

.box-divider--light-all-2 {
    box-shadow: inset 0 0 0 2px #e3e8ee;
}

.box-background--blue {
    background-color: #5469d4;
}

.box-background--white {
    background-color: #ffffff;
}

.box-background--blue800 {
    background-color: #212d63;
}

.box-background--gray100 {
    background-color: #e3e8ee;
}

.box-background--cyan200 {
    background-color: #7fd3ed;
}

.padding-top--64 {
    padding-top: 64px;
}

.padding-top--24 {
    padding-top: 24px;
}

.padding-top--48 {
    padding-top: 48px;
}

.padding-bottom--24 {
    padding-bottom: 24px;
}

.padding-horizontal--48 {
    padding: 48px;
}

.padding-bottom--15 {
    padding-bottom: 15px;
}

.flex-justifyContent--center {
    -ms-flex-pack: center;
    justify-content: center;
}

.formbg {
    margin: 0px auto;
    width: 100%;
    max-width: 448px;
    background: white;
    border-radius: 4px;
    box-shadow: rgba(60, 66, 87, 0.12) 0px 7px 14px 0px,
        rgba(0, 0, 0, 0.12) 0px 3px 6px 0px;
}

/* span {
  display: block;
  font-size: 20px;
  line-height: 28px;
  color: #1a1f36;
}

label {
  margin-bottom: 10px;
} */

.reset-pass a,
label {
    font-size: 14px;
    font-weight: 600;
    display: block;
}

.reset-pass>a {
    text-align: right;
    margin-bottom: 10px;
}

.grid--50-50 {
    display: grid;
    grid-template-columns: 50% 50%;
    align-items: center;
}

.field input {
    font-size: 16px;
    line-height: 28px;
    padding: 8px 16px;
    width: 100%;
    min-height: 44px;
    border: unset;
    border-radius: 4px;
    outline-color: rgb(84 105 212 / 0.5);
    background-color: rgb(255, 255, 255);
    box-shadow: rgba(0, 0, 0, 0) 0px 0px 0px 0px, rgba(0, 0, 0, 0) 0px 0px 0px 0px,
        rgba(0, 0, 0, 0) 0px 0px 0px 0px, rgba(60, 66, 87, 0.16) 0px 0px 0px 1px,
        rgba(0, 0, 0, 0) 0px 0px 0px 0px, rgba(0, 0, 0, 0) 0px 0px 0px 0px,
        rgba(0, 0, 0, 0) 0px 0px 0px 0px;
}

input[type="submit"] {
    background-color: rgb(84, 105, 212);
    box-shadow: rgba(0, 0, 0, 0) 0px 0px 0px 0px, rgba(0, 0, 0, 0) 0px 0px 0px 0px,
        rgba(0, 0, 0, 0.12) 0px 1px 1px 0px, rgb(84, 105, 212) 0px 0px 0px 1px,
        rgba(0, 0, 0, 0) 0px 0px 0px 0px, rgba(0, 0, 0, 0) 0px 0px 0px 0px,
        rgba(60, 66, 87, 0.08) 0px 2px 5px 0px;
    color: #fff;
    font-weight: 600;
    cursor: pointer;
}

.field-checkbox input {
    width: 20px;
    height: 15px;
    margin-right: 5px;
    box-shadow: unset;
    min-height: unset;
}

.field-checkbox label {
    display: flex;
    align-items: center;
    margin: 0;
}

.img-width-80 {
    width: 80px;
}

a.ssolink {
    display: block;
    text-align: center;
    font-weight: 600;
}

.footer-link span {
    font-size: 14px;
    text-align: center;
}

.listing a {
    color: #697386;
    font-weight: 600;
    margin: 0 10px;
}

.animationRightLeft {
    animation: animationRightLeft 2s ease-in-out infinite;
}

.animationLeftRight {
    animation: animationLeftRight 2s ease-in-out infinite;
}

.tans3s {
    animation: animationLeftRight 3s ease-in-out infinite;
}

.tans4s {
    animation: animationLeftRight 4s ease-in-out infinite;
}

@keyframes animationLeftRight {
    0% {
        transform: translateX(0px);
    }

    50% {
        transform: translateX(1000px);
    }

    100% {
        transform: translateX(0px);
    }
}

@keyframes animationRightLeft {
    0% {
        transform: translateX(0px);
    }

    50% {
        transform: translateX(-1000px);
    }

    100% {
        transform: translateX(0px);
    }
}

.error {
    color: red;
}

.Invalid_input {
    color: red;
    font-size: 15px;
}

input:disabled {
    cursor: not-allowed;
}

h1.studentslogin {
    font-family: -apple-system, BlinkMacSystemFont, Segoe UI, Roboto, Helvetica Neue, Ubuntu, sans-serif;
}

input[type="submit"] {
    background-color: #0a508c;
    border-color: #0a508c;
}

i.fa.fa-map-marker-alt.mr-2.usernames {
    font-size: 30px;
    color: black;
    line-height: inherit;
}

i.fa.fa-id-card.usernames {
    font-size: 30px;
    color: black;
    line-height: inherit;
}

i.fa.fa-envelope.usernames {
    font-size: 30px;
    color: black;
    line-height: inherit;
}

label.labelData {
    font-size: 30px;
    color: black;
    font-family: "Jost", sans-serif;
}

label.labelDatas {
    font-size: 25px;
    color: #afb1b4;
    font-family: "Jost", sans-serif;
}

a#basic-nav-dropdowns {
    font-family: "Jost", sans-serif;
    font-weight: 800;
    color: white;

}

button.btn-btn-primary.eyepass {
    float: right;
    position: relative;
    bottom: 37px;
    left: -15px;
    background: none;
    border: none;

}

span.error.text-danger {
    border: none !important;
}

i.fa.fa-map-marker-alt.usernames {
    font-size: 30px;
    color: black;
    line-height: inherit;
}

@media only screen and (max-width: 576px) {
    .cardStyle {
        width: 500px;
        border-color: white;
        background: #fff;
        padding: 36px 0;
        border-radius: 4px;
        margin: 30px 0;
        box-shadow: 0px 0 5px 0 rgba(0, 0, 0, 0.25);
        margin-right: 0px;
    }

    .formbg {
        margin: 0px auto;
        width: 80%;
        max-width: 448px;
        background: white;
        border-radius: 4px;
        box-shadow: rgb(60 66 87 / 12%) 0px 7px 14px 0px, rgb(0 0 0 / 12%) 0px 3px 6px 0px;
    }

    input[type="password"] {
        box-shadow: rgb(0 0 0 / 0%) 0px 0px 0px 0px, rgb(0 0 0 / 0%) 0px 0px 0px 0px, rgb(0 0 0 / 0%) 0px 0px 0px 0px, rgb(60 66 87 / 16%) 0px 0px 0px 1px, rgb(0 0 0 / 0%) 0px 0px 0px 0px, rgb(0 0 0 / 0%) 0px 0px 0px 0px, rgb(0 0 0 / 0%) 0px 0px 0px 0px;
    }

    input[type="text"] {
        box-shadow: rgb(0 0 0 / 0%) 0px 0px 0px 0px, rgb(0 0 0 / 0%) 0px 0px 0px 0px, rgb(0 0 0 / 0%) 0px 0px 0px 0px, rgb(60 66 87 / 16%) 0px 0px 0px 1px, rgb(0 0 0 / 0%) 0px 0px 0px 0px, rgb(0 0 0 / 0%) 0px 0px 0px 0px, rgb(0 0 0 / 0%) 0px 0px 0px 0px;
    }

    input.contact-form {
        box-shadow: none;
    }
}