.loader-background {
    width: 100%;
    height: 100vh;
    position: fixed;
    display: flex;
    justify-content: center;
    align-items: center;
    z-index: 10000;
    background: #ffffffc7;
}

.loader-img {
    width: 150px;
}


.loader-walk {
    width: 100px;
    height: 40px;
    position: absolute;
    top: 50%;
    left: 50%;
    transform: translate(-50%, -50%);
    background-color: white;
}

.loader-walk>div {
    content: "";
    width: 20px;
    height: 20px;
    background: #2196F3;
    border-radius: 100%;
    position: absolute;
    -webkit-animation: animate 2s linear infinite;
    animation: animate 2s linear infinite;
}

.loader-walk>div:nth-of-type(1) {
    -webkit-animation-delay: -.4s;
    animation-delay: -.4s;
}

.loader-walk>div:nth-of-type(2) {
    -webkit-animation-delay: -.8s;
    animation-delay: -.8s;
}

.loader-walk>div:nth-of-type(3) {
    -webkit-animation-delay: -1.2s;
    animation-delay: -1.2s;
}

.loader-walk>div:nth-of-type(4) {
    -webkit-animation-delay: -1.6s;
    animation-delay: -1.6s;
}

@-webkit-keyframes animate {
    0% {
        left: 100px;
        top: 0;
    }

    80% {
        left: 0;
        top: 0;
    }

    85% {
        left: 0;
        top: -20px;
        width: 20px;
        height: 20px;
    }

    90% {
        width: 40px;
        height: 15px;
    }

    95% {
        left: 100px;
        top: -20px;
        width: 20px;
        height: 20px;
    }

    100% {
        left: 100px;
        top: 0;
    }
}

@keyframes animate {
    0% {
        left: 100px;
        top: 0;
    }

    80% {
        left: 0;
        top: 0;
    }

    85% {
        left: 0;
        top: -20px;
        width: 20px;
        height: 20px;
    }

    90% {
        width: 40px;
        height: 15px;
    }

    95% {
        left: 100px;
        top: -20px;
        width: 20px;
        height: 20px;
    }

    100% {
        left: 100px;
        top: 0;
    }
}